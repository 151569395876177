import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20250207-Rate-Sheet.pdf";
const date = "07.02.2025";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>
      <strong>Essential reading article template</strong>
    </p>
    <p>Friday 07 February 2025, HSBC UK</p>
    <p>
      <strong>Changes to our mortgage products</strong>
    </p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      <strong>
        Following the Monetary Policy Committee announcement on Thursday 06 February 2025, the Bank of England Base Rate
        has reduced from 4.75% to 4.50%.{" "}
      </strong>
    </p>
    <p>With effect from Friday 07 February 2025 the changes are as follows:</p>
    <ul>
      <li>All tracker rates will include the reduced Bank of England Base Rate.</li>
      <li>There are no changes to our Residential Standard Variable Rate or Buy to Let Standard Variable Rate.</li>
    </ul>
    <p>There are no changes to any other interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Friday 07 February 2025 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
